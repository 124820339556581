import {Theme} from '@shared/model/layout/theme';
import {MenuOrientation} from '@shared/model/layout/menu-orientation';

export class UserProfile {
  constructor(
    public authenticationIdentifier: string = '',
    public email: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public fullName: string = '',
    public company: string = '',
    public imageURL: string = '',
    public vatNumber: string = '',
    public verifiedSince: string = '',
    public permissions: string[] = [],
    public preferences: UserPreferences = new UserPreferences()
  ) {}

  static adopt(profile: any): UserProfile {
    return new UserProfile(
      profile.authenticationIdentifier,
      profile.email,
      profile.firstName,
      profile.lastName,
      profile.fullName,
      profile.company,
      profile.imageURL,
      profile.vatNumber,
      profile.verifiedSince,
      profile.permissions,
      profile.preferences ? UserPreferences.adopt(profile.preferences) : new UserPreferences()
    );
  }
}

export class UserPreferences {
  constructor(
    public theme: Theme = Theme.LIGHT,
    public menuOrientation: MenuOrientation = MenuOrientation.OVERLAY,
  ) { }

  static adopt(preferences: any) {
    return new UserPreferences(
      preferences.theme,
      preferences.menuOrientation
    );
  }
}
