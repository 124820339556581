<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" novalidate>
  <div class="card">
    <span class="block text-900 font-bold text-xl mb-4">Vertegenwoordiger</span>
    <div class="grid grid-nogutter flex-wrap gap-3 p-fluid">
      <div class="col-12 lg:col-8">
        <div class="grid formgrid">
          <!-- First Name field -->
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="firstName" formControlName="firstName"
                     [ngClass]="{'ng-dirty ng-invalid': form.hasError('nameOrCompanyRequired') && !form.get('company')?.value && form.touched}"/>
              <label for="firstName">Voornaam</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.hasError('nameOrCompanyRequired') && !form.get('company')?.value && (form.get('firstName')?.touched || form.touched)">
              Vul zowel voornaam als achternaam in, of vul een bedrijfsnaam in
            </small>
          </div>

          <!-- Last Name field -->
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="lastName" formControlName="lastName"
                     [ngClass]="{'ng-dirty ng-invalid': form.hasError('nameOrCompanyRequired') && !form.get('company')?.value && form.touched}"/>
              <label for="lastName">Achternaam</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.hasError('nameOrCompanyRequired') && !form.get('company')?.value && (form.get('lastName')?.touched || form.touched)">
              Vul zowel voornaam als achternaam in, of vul een bedrijfsnaam in
            </small>
          </div>
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="email" formControlName="email"
                     [ngClass]="{'ng-dirty ng-invalid': form.get('email')?.invalid && form.get('email')?.touched}"/>
              <label for="email">Email</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.get('email')?.invalid && form.get('email')?.touched">
              {{getEmailErrorMessage()}}
            </small>
          </div>
          <div class="col-12  md:col-6 field">
            <!-- Phone field -->
              <p-floatLabel class="m-5">
                <input pInputText id="phone" formControlName="phone"
                       [ngClass]="{'ng-dirty ng-invalid': form.hasError('phoneOrGsmRequired') && form.get('phone')?.touched && form.get('gsm')?.touched}"/>
                <label for="phone">Telefoon</label>
              </p-floatLabel>
              <small class="p-error block" *ngIf="form.hasError('phoneOrGsmRequired') && (form.get('phone')?.touched || form.touched)">
                Vul ten minste een telefoonnummer of GSM nummer in
              </small>
          </div>
          <div class="col-12  md:col-6 field">
            <!-- GSM field -->
              <p-floatLabel class="m-5">
                <input pInputText id="gsm" formControlName="gsm"
                       [ngClass]="{'ng-dirty ng-invalid': form.hasError('phoneOrGsmRequired') && form.get('phone')?.touched && form.get('gsm')?.touched}"/>
                <label for="phone">GSM</label>
              </p-floatLabel>
              <small class="p-error block" *ngIf="form.hasError('phoneOrGsmRequired') && (form.get('gsm')?.touched || form.touched)">
                Vul ten minste een telefoonnummer of GSM nummer in
              </small>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="fax" formControlName="fax"/>
              <label for="fax">Fax</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="website" formControlName="website"/>
              <label for="phone">website</label>
            </p-floatLabel>
          </div>
          <!-- Company field -->
          <div class="col-12 md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="company" formControlName="company"
                     [ngClass]="{'ng-dirty ng-invalid': form.hasError('nameOrCompanyRequired') && (!form.get('firstName')?.value || !form.get('lastName')?.value) && form.touched}"/>
              <label for="company">Bedrijf</label>
            </p-floatLabel>
            <small class="p-error block" *ngIf="form.hasError('nameOrCompanyRequired') && (!form.get('firstName')?.value || !form.get('lastName')?.value) && (form.get('company')?.touched || form.touched)">
              Vul een bedrijfsnaam in, of vul zowel voornaam als achternaam in
            </small>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="street" formControlName="street"/>
              <label for="street">Straat</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="houseNumber" formControlName="houseNumber"/>
              <label for="houseNumber">huisnummer</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="city" formControlName="city"/>
              <label for="city">Gemeente</label>
            </p-floatLabel>
          </div>
          <div class="col-12  md:col-6 field">
            <p-floatLabel class="m-5">
              <input pInputText id="country" formControlName="country"/>
              <label for="country">country</label>
            </p-floatLabel>
          </div>
        </div>
      </div>

      <div class="flex-1 w-full lg:w-3 xl:w-4 flex flex-column row-gap-3 tw-mt-5">

        <div class="flex flex-column sm:flex-row justify-content-between align-items-center gap-3 py-2">
          <button pButton pRipple class="p-button-danger flex-1 p-button-outlined"
                  label="{{'user.edit.details.cancelButton' | translate }}" icon="pi pi-fw pi-arrow-circle-left"
                  (click)="goToOverview()"></button>
          <button pButton pRipple class="p-button-primary flex-1 border-round"
                  label="{{'user.edit.details.createButton' | translate }}" icon="pi pi-fw pi-check"
                  (click)="submit()"></button>
        </div>
      </div>
    </div>
  </div>
</form>
