export class Page<T> {
  constructor(
    public content: T[] = [],
    public last: boolean = false,
    public first: boolean = true,
    public pageSize: number = 20,
    public pageNumber: number = 0,
    public totalPages: number = 0,
    public totalElements: number = 0,
    public numberOfElementsInPage: number = 0,
    public empty: boolean = false,
    public pageable: Pageable = new Pageable(),
    public sort: Sort = new Sort(),
  ) {}

  public static adapt<T>(item: any, data: T[]): Page<T> {
    return new Page(
      data,
      item.last,
      item.first,
      item.size,
      item.number,
      item.totalPages,
      item.totalElements,
      item.numberOfElements,
      item.empty,
      Pageable.adapt(item.pageable),
      Sort.adapt(item.sort)
    );
  }
}

export class Pageable {
  constructor(
    public sort: Sort = new Sort(),
    public pageNumber: number = 0,
    public pageSize: number = 20,
    public totalElements: number = 0,
    public offset: number = 0,
    public paged: boolean = true,
    public unpaged: boolean = false,
  ) {}

  public static adapt(item: any): Pageable {
    if (item === undefined || item === null) {
      return new Pageable();
    }

    return new Pageable(
      Sort.adapt(item.sort),
      item.pageNumber,
      item.pageSize,
      item.totalElements,
      item.offset,
      item.paged,
      item.unpaged
    );
  }
}

export class Sort {
  constructor(
    public sorted: boolean = false,
    public unsorted: boolean = true,
    public empty: boolean = true,
  ) {}

  public static adapt(item: any): Sort {
    if (item === undefined || item === null) {
      return new Sort();
    }

    return new Sort(
      item.sorted,
      item.unsorted,
      item.empty
    );
  }
}
