export const bridgeRoutesNames = {
  OVERVIEW: '',
  PRODUCT_OVERVIEW: 'product',
  PRODUCT_EDIT: 'product/:id/edit',
  ACTION_EDIT: 'action/:id/edit',
  CUSTOMER_OVERVIEW: 'customer',
  CUSTOMER_EDIT: 'customer/:id/edit',
  STOCK_OVERVIEW: 'stock',
  STOCK_EDIT: 'stock/:id/edit',
  PRODUCT_LINKING: 'product/linking',
  PRODUCT_MATCHING: 'product/matching',
  PRODUCT_PROPOSALS: 'product/proposals',
  PROPOSAL_EDIT: 'proposal/:id/edit',

};
