import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConfigService} from '@core/providers/app-config.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Representative} from '@shared/model/representative';
import {Page} from '@shared/model/page';

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService {
  private readonly _privateApi: string;

  constructor(
    private http: HttpClient,
    private configProvider: AppConfigService
  ) {
    this._privateApi = this.configProvider.config().privateApiBase;
  }

  readRepresentatives(page: number = 0, size: number = 20, sort?: string): Observable<Page<Representative>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (sort) {
      params = params.set('sort', sort);
    }

    return this.http.get<Page<Representative>>(`${this._privateApi}/representatives`, { params });
  }

  editRepresentative(representativeId: number, representative: Representative): Observable<number> {
    const representativeUrl = `${this.configProvider.config().privateApiBase}/representatives/${representativeId}`;

    return this.http.put<any>(representativeUrl, representative).pipe(
      map(response => response.representativeId)
    );
  }

  show(representativeId: number): Observable<Representative> {
    const representativeUrl = `${this.configProvider.config().privateApiBase}/representatives/${representativeId}`;
    return this.http.get<Representative>(representativeUrl).pipe(
      map(Representative.adapt)
    );
  }

  create(representative: Representative): Observable<string> {
    const representativesUrl = `${this._privateApi}/representatives`;
    return this.http.post<any>(representativesUrl, representative).pipe(
      tap(response => console.log(response))
    );
  }

  deleteRepresentative(representativeId: number): Observable<any> {
    const representativesUrl = `${this._privateApi}/representatives/${representativeId}`;

    return this.http.delete(representativesUrl);

  }
}

export class RepresentativeSearchParameters {
  constructor(
    public firstName: string = '',
    public lastName: string = '',
    public company: string = '',
    public email: string = '',
    public page: number = null,
    public size: number = null,
  ) {
  }

  build(): HttpParams {
    let params = new HttpParams();

    if (this.firstName) {
      params = params.append('firstName', this.firstName);
    }
    if (this.lastName) {
      params = params.append('lastName', this.lastName);
    }
    if (this.company) {
      params = params.append('company', this.company);
    }
    if (this.email) {
      params = params.append('email', this.email);
    }
    if (this.page) {
      params = params.append('page', String(this.page - 1));
    }
    if (this.size) {
      params = params.append('size', String(this.size));
    }

    return params;
  }
}
