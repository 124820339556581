import {Component} from '@angular/core';
import {ButtonDirective} from 'primeng/button';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgIf} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {SharedModule} from '@shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {Representative} from '@shared/model/representative';
import {Router} from '@angular/router';
import {LoadingIndicatorService} from '@core/services/loading-indicator.service';
import {FormUtils} from '@core/utils/form.utils';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {appRoutesLinks} from '@app/app.routes.links';
import {RepresentativeService} from '@core/http/representative.service';
import {FloatLabelModule} from 'primeng/floatlabel';
import {CustomValidators} from '@features/representative/custom-validators';
import {finalize} from 'rxjs';

@Component({
  selector: 'app-representative-create',
  standalone: true,
  imports: [
    ButtonDirective,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    Ripple,
    SharedModule,
    TranslateModule,
    FloatLabelModule
  ],
  templateUrl: './representative-create.component.html'
})
export class RepresentativeCreateComponent {

  public representative: Representative = new Representative();
  public form: FormGroup;


  constructor(
    private router: Router,
    private representativeService: RepresentativeService,
    private formBuilder: FormBuilder,
    private loadingIndicatorService: LoadingIndicatorService,
    private formUtils: FormUtils,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbService.setItemss([
      {
        icon: 'pi pi-home',
        route: appRoutesLinks.HOME
      },
      {
        label: 'Vertegenwoordigers',
        route: appRoutesLinks.REPRESENTATIVE_OVERVIEW
      },
      {
        label: 'Vertegenwoordiger Aanmaken'
      }
    ]);

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      firstName: [this.representative.firstName],
      lastName: [this.representative.lastName],
      phone: [this.representative.phone],
      gsm: [this.representative.gsm],
      fax: [this.representative.fax],
      email: [this.representative.email, [Validators.required, Validators.email]], // Both required and email format validation      street: [this.representative.street],
      houseNumber: [this.representative.houseNumber],
      city: [this.representative.city],
      country: [this.representative.country],
      company: [this.representative.company],
      website: [this.representative.website],
    }, {validators: [CustomValidators.phoneOrGsmValidator(), CustomValidators.nameOrCompanyValidator()]});
  }

  public submit() {
    if (!this.form.valid) {
      this.formUtils.validateAllFormFields(this.form);

      // Handle form-level validation messages
      const emailControl = this.form.get('email');
      if (emailControl?.hasError('required')) {
        this.formUtils.showErrorMessage('Validatie fout', 'Email is verplicht');
      } else if (emailControl?.hasError('email')) {
        this.formUtils.showErrorMessage('Validatie fout', 'Voer een geldig emailadres in');
      } else if (this.form.hasError('phoneOrGsmRequired')) {
        this.formUtils.showErrorMessage('Validatie fout', 'Vul ten minste een telefoonnummer of GSM nummer in');
      } else if (this.form.hasError('nameOrCompanyRequired')) {
        this.formUtils.showErrorMessage('Validatie fout', 'Vul een bedrijfsnaam in, of vul zowel voornaam als achternaam in');
      }
      return;
    }

    this.loadingIndicatorService.show();
    const representative = this.form.getRawValue();

    this.representativeService.create(representative)
      .pipe(
        finalize(() => this.loadingIndicatorService.hide())
      )
      .subscribe({
        next: (result) => {
          this.formUtils.showSuccessMessage(
            'Vertegenwoordiger aangemaakt',
            'De vertegenwoordiger werd succesvol aangemaakt'
          );
          this.router.navigate([appRoutesLinks.REPRESENTATIVE_OVERVIEW]);
        },
        error: (error) => {
          this.formUtils.showErrorMessage(
            'Fout bij aanmaken',
            'Er is een fout opgetreden bij het aanmaken van de vertegenwoordiger'
          );
        }
      });
  }

  goToOverview() {
    this.router.navigate(appRoutesLinks.REPRESENTATIVE_OVERVIEW);
  }

  getEmailErrorMessage(): string {
    const emailControl = this.form.get('email');
    if (emailControl?.hasError('required')) {
      return 'Email is verplicht';
    }
    if (emailControl?.hasError('email')) {
      return 'Voer een geldig emailadres in';
    }
    return '';
  }
}
