import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormUtils {
  constructor(private messageService: MessageService,
              private translateService: TranslateService) { }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl && !control.disabled) {
        control.markAsTouched();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control as FormGroup);
      }
    });
  }

  showSuccessMessage(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant(summary),
      detail: this.translateService.instant(detail),
      life : 2000
    });
  }

  showSuccessMessageWithLife(summary: string, detail: string, lifetime: number) {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant(summary),
      detail: this.translateService.instant(detail),
      life: lifetime
    });
  }

  showErrorMessage(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant(summary),
      detail: this.translateService.instant(detail),
      life : 2000
    });
  }
}
