import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {VersionService} from '@core/utils/version.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  versionInfo: { version: string; gitHash: string; } | undefined;

  ngOnInit(): void {
    this.versionInfo = this.versionService.getVersionInfo();
  }

  constructor(public layoutService: LayoutService,  private versionService: VersionService) { }
}
