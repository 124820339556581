import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static phoneOrGsmValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phone = control.get('phone')?.value;
      const gsm = control.get('gsm')?.value;

      if (!phone && !gsm) {
        return { phoneOrGsmRequired: true };
      }
      return null;
    };
  }

  static nameOrCompanyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstName = control.get('firstName')?.value;
      const lastName = control.get('lastName')?.value;
      const company = control.get('company')?.value;

      if ((!firstName || !lastName) && !company) {
        return { nameOrCompanyRequired: true };
      }
      return null;
    };
  }
}
